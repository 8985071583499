<script setup lang="ts">
import ui from '/~/core/ui'
import AppHeaderLogo from '/~/components/app/header/app-header-logo.vue'
import { useCms } from '/~/composables/cms/use-cms'

const { headerLogoMaxWidth } = useCms()
</script>

<template>
  <app-header-logo
    class="mr-auto py-2.5"
    :max-width="ui.mobile ? 92 : headerLogoMaxWidth"
  />
</template>
